import React, { useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { postRequest } from "../../apis/axiosAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import firebase from "firebase/app";
import "firebase/storage";

function getModalStyle() {
    return {
        top: "5%",
        left: "5%",
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: "absolute",
            width: "90%",
            height: "90%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(1),
            overflowX: "scroll",
        },
        gap: {
            height: "15px",
        },
        homePadding: {
            padding: "10px",
        },
        homeContents: {
            height: "90%",
        },
        homeMainMenu: {
            fontWeight: "bold",
            fontSize: "20px",
            color: "white"
        },
        homeButton: {
            borderRadius: "10px",
            width: "60px",
            height: "54px",
            backgroundColor: "#00CC99",
            "&:hover": {
                background: "#00CC99",
            },
        },
        authButtonDouble: {
            borderRadius: "10px",
            width: "470px",
            height: "108px",
            backgroundColor: "orange",
            "&:hover": {
                background: "orange",
            },
        },
        button: {
            backgroundColor: "#fff",
            "&:hover": {
                background: "#d9d9d9",
            },
            height: "40px",
            width: "40px",
            padding: "10px",
        },
        paper: {
            position: "fixed",
            inset: 0,
            margin: "auto",
            width: "85vw",
            height: "85vh",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(1, 2, 1),
            "&:focus": {
                outline: "none",
            },
            borderRadius: "5px",
        },
        header: {
            borderBottom: "1px solid #dee2e6",
            padding: "8px",
            display: "flex",
        },
        formGroup: {
            padding: "8px",
        },
        inputFileName: {
            padding: "5px",
            width: "450px",
            height: "20px",
        },
        selectFileButton: {
            margin: "0 30px",
            cursor: "pointer",
            width: "130px",
            height: "30px",
            padding: "8px",
            backgroundColor: "#00cc5b",
            border: "none",
            borderRadius: "5px",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#33d67c",
            },
        },
        uploadButton: {
            width: "120px",
            height: "40px",
            padding: "8px",
            backgroundColor: "#00cc5b",
            border: "none",
            borderRadius: "5px",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#33d67c",
            },
        },
        disabledUploadButton: {
            width: "120px",
            height: "40px",
            padding: "8px",
            color: "#FFF",
            background: "#AAA",
        },
        closeButton: {
            margin: "0 0 0 auto",
            "&:hover": {
                backgroundColor: "lightgray",
            },
        },
        dialogCloseButton: {
            backgroundColor: "#00cc5b",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#33d67c",
            },
        }
    })
);

// Propsの型定義
interface UserMasterManagementProps {
    // 権限管理を行うアプリID(アルファベット) 例) customer-support-map
    appId: string;
    // 権限管理アプリ名(和名) 例) ～権限管理
    appName: string;
    // 順番
    index: number;
}

const UserMasterManagement: React.FC<UserMasterManagementProps> = ({ appId, appName, index }) => {
    const classes = useStyles({});
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [file, setFile] = React.useState<FileList | null>(null);
    const [fileName, setFileName] = React.useState("");
    const [uploadFileName, setUploadfileName] = React.useState("");
    const [uploadFilePath, setUploadFilePath] = React.useState("");
    const [uploadFileUuid, setUploadFileUuid] = React.useState("");
    const [isUploaded, setIsUploaded] = React.useState(false);
    const [isFinished, setIsFinished] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const isFirstRender = useRef(false);

    useEffect(() => {
        // このeffectは初回レンダー時のみ呼ばれるeffect
        isFirstRender.current = true;
    }, []);

    // グラフの表示・非表示
    const handleOpen = () => {
        setOpen(true);
        setCount(count + 1);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenDialog = (message: string) => {
        setDialogMessage(message);
        setDialog(true);
    };

    const handleCloseDialog = () => {
        setDialog(false);
        if (isFinished) {
            handleClose();
        }
    };

    /**
     * ファイル選択後、下記を行う
     * ファイル形式チェック
     * Google Cloud Storageへのファイルアップロード
     * API経由でアップロードされたファイルの中身をBigQueryに保存
     * ユーザーに通知する     * 
     */
    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsFinished(false);
        setIsUploaded(false);
        setIsLoading(true);
        const files = e.target.files;
        if (files && files[0]) {
            setFile(files);
            setFileName(files[0].name);
        } else {
            setFile(null);
            setFileName("");
            return;
        }

        // CSVファイルであることを確認する
        const matchName = /\.csv$/i;

        let fileObject: any = {};

        const uploadFileList: FileList = files
        if (!uploadFileList || uploadFileList.length === 0) {
            return;
        }

        // ファイル名検査
        for (let i = 0; i < uploadFileList.length; i++) {
            let inputFileName = uploadFileList[i].name;
            if (inputFileName.match(matchName)) {
                fileObject[i] = uploadFileList[i];
            } else {
                const message = "ファイル名の形式が正しくありません。";
                handleOpenDialog(message);
                return;
            }
        }
        // ファイル名を生成
        const date = new Date();
        const uploadFileName =
            fileObject[0].name.slice(0, -4) + '-' + // 元のファイル名から拡張子(.csv)を除外してハイフンを追加
            date.getFullYear().toString() +
            (date.getMonth() + 1).toString().padStart(2, '0') +
            date.getDate().toString().padStart(2, '0') + '-' + // 日付の区切りにハイフンを使用
            date.getHours().toString().padStart(2, '0') +
            date.getMinutes().toString().padStart(2, '0') +
            date.getSeconds().toString().padStart(2, '0') +
            '.csv'; // 新しい拡張子を追加
        const uploadFilePath = "user-master/" + appId + "/" + uploadFileName;
        // 値を保存する
        setUploadfileName(uploadFileName)
        setUploadFilePath(uploadFilePath)
        const uuid = uuidv4()
        setUploadFileUuid(uuid)

        // アップロードされたCSVをBigQueryの一時テーブルに保存する
        const callTemporaryDataImportApi = async (filePath: string, fileName: string, uuid: string) => {
            const url = process.env.REACT_APP_DIGITAL_TOOL_URL_API! + "j21spb32/user-master/file-import";
            const param = {
                appId: appId,
                fileUuid: uuid,
                file_path: filePath,
                originalFileName: fileName
            };
            await postRequest(url, param);
        };

        let storageRef = firebase.storage().ref();
        const uploadFunc = async (uuid: string) => {
            for (let key in fileObject) {
                let uploadRef = storageRef.child(uploadFilePath);
                // ファイルアップロード
                await uploadRef
                    .put(fileObject[key])
                    .then((snapshot) => {
                    })
                    .catch((error) => {
                        console.log(error);
                        const errorMsg = "ファイルアップロードに失敗しました。";
                        handleOpenDialog(errorMsg);
                        return;
                    });
                await callTemporaryDataImportApi(uploadFilePath, uploadFileName, uuid);
                const finishMsg = "ファイルアップロードが完了しました。";
                handleOpenDialog(finishMsg);
                setIsUploaded(true)
                setIsLoading(false);
            }
        };
        uploadFunc(uuid);
    };

    /**
     * 一時テーブルに保存したデータのuuidをキーとして権限管理マスタの更新を行う
     * @param updateMaster
     * @returns 
     */
    const updateMaster = async (uploadFileList: FileList | null) => {
        if (!uploadFileList || uploadFileList.length === 0) {
            return;
        }
        setIsLoading(true);
        setIsUploaded(false);
        // csvのアップロード後にBigQueryへの取り込みAPIを呼ぶ
        const callDataTransportApi = async (filePath: string, fileName: string, uuid: string) => {
            const url = process.env.REACT_APP_DIGITAL_TOOL_URL_API! + "j21spb32/user-master/master-update";
            const param = {
                appId: appId,
                fileUuid: uuid,
            };
            await postRequest(url, param);
        };
        await callDataTransportApi(uploadFilePath, uploadFileName, uploadFileUuid);
        setIsFinished(true);
        // TODO もっとメッセージが必要かも
        const finishMsg = "ファイル取込が完了しました。";
        handleOpenDialog(finishMsg);
        setIsLoading(false);
    };

    /**
     * ローディング画面の表示
     */
    const LoadingSpinner = () => {
        return (
            <div style={{ justifyContent: "center", display: "flex" }}>
                <CircularProgress
                    size={"40vh"}
                    style={{
                        color: "#ccc",
                        top: "20vh",
                        position: "absolute"
                    }}
                />
            </div>
        );
    }

    /**
     * モーダル画面
     */
    const ModalButtonDisplay = () => {
        return (
            <>
                <React.Fragment>
                    {(
                        <>
                            <Grid className={classes.gap}></Grid>
                            <Grid
                                container
                                alignItems="center"
                                justify="center"
                                className={classes.homePadding}
                            >
                                <Button
                                    className={classes.authButtonDouble}
                                >
                                    <Grid container>
                                        <Grid item xs={12}
                                            onClick={() => {
                                                // userLogging("権限マスタボタン押下:" + app, "");
                                                handleOpen()
                                            }}
                                        >
                                            <Typography className={classes.homeMainMenu}>
                                                <span className={classes.homeMainMenu}>
                                                    {appName}
                                                    <br />
                                                    権限管理
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        </>
                    )}
                </React.Fragment>
            </>
        )
    }

    /**
     * モーダル画面
     */
    const ModalContentDisplay = () => {
        return (
            <>
                <div className={classes.header}>
                    <Typography variant="h5">アプリ権限マスタ管理({appName})</Typography>
                    <IconButton
                        className={classes.closeButton}
                        onClick={() => handleClose()}
                        size={"small"}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.formGroup}>
                    {/* <div>
                        権限管理情報を記載してアップロード後、「反映する」ボタンを押下してください
                    </div> */}
                    <input
                        type="text"
                        value={fileName}
                        className={classes.inputFileName}
                        disabled={true}
                    />
                    <label className={classes.selectFileButton}>
                        <input
                            type="file"
                            onChange={onChangeFile}
                            style={{ display: "none" }}
                        />
                        ファイルをアップロードする
                    </label>
                </div>
                <div style={{ textAlign: "center" }}>
                    <Button
                        className={
                            !isUploaded
                                ? classes.disabledUploadButton
                                : classes.uploadButton
                        }
                        onClick={() => updateMaster(file)}
                        disabled={!isUploaded}
                    >
                        反映する
                    </Button>
                </div>
            </>
        );
    }

    return (
        <>
            {/* モーダル前の部分 */}
            <ModalButtonDisplay />
            {/* モーダル表示後の部分 */}
            <Modal open={open} onClose={handleClose}>
                <div className={classes.paper}>
                    <>
                        {isLoading ? <LoadingSpinner /> : null}
                    </>
                    <ModalContentDisplay />
                </div>
            </Modal>
            {/* ダイアログ */}
            <Dialog open={dialog}>
                <DialogContent>{dialogMessage}</DialogContent>
                <DialogActions>
                    <Button
                        className={classes.dialogCloseButton}
                        onClick={handleCloseDialog}
                    >
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserMasterManagement;